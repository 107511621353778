import { useAppDispatch, useAppSelector } from 'store';

import { CompanyActions } from 'store/company';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CompanySelectors } from 'store/company/company.selectors';
import { useEffect } from 'react';
import { FetchingStatus } from 'store/interfaces';
import { actions } from 'store/company/company.slice';
import qs from 'qs';

const Login = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const data = useAppSelector(CompanySelectors.getCompanyData);
  const fetchStatus = useAppSelector(CompanySelectors.getFetchingStatus);
  const signupId = useAppSelector(CompanySelectors.getSignupId);
  const isVoucherError = useAppSelector(store => store.company.errorVoucher);
  const hasVoucher = data?.proFit;
  const path = hasVoucher ? `/${id}/step/0` : `/${id}/step/1`;

  useEffect(() => {
    if (signupId !== id || search.includes('token')) {
      dispatch(actions.resetFullState(id || ''));
      localStorage.setItem('firstLoading', 'true');
    }
  }, []);

  useEffect(() => {
    if (id) {
      localStorage.removeItem('firstLoading');
      dispatch(CompanyActions.getCompanyInfo(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const LS = localStorage.getItem('firstLoading');
    if (!LS && id !== undefined) {
      if (fetchStatus === FetchingStatus.REJECTED) {
        navigate(`/`);
      } else if (fetchStatus === FetchingStatus.FULFILLED) {
        if (search.includes('token')) {
          const querySearchParams = qs.parse(search, { ignoreQueryPrefix: true });
          navigate(`/${id}/verification/${querySearchParams.token}`);
          return;
        }
        if (data?.password === 'Yes') {
          navigate(`/${id}/password`);
        } else {
          if (hasVoucher && isVoucherError) {
            navigate(path);
            return;
          }
          const firstPath = localStorage.getItem('firstPath');
          if (
            data?.landingPage &&
            firstPath &&
            !firstPath.toLowerCase().includes(path.toLowerCase())
          )
            navigate(`/${id}/landing`);
          else {
            navigate(path);
          }
        }
      }
    }
  }, [data, fetchStatus]);
  return null;
};

export default Login;
