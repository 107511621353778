import { RaffleBannerWrapper } from './RaffleBanner.styles';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CoupenPhotoIcon } from 'assets/icons/raffle-icon.svg';
import { useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { CompanySelectors } from 'store/company/company.selectors';
import { useMemo } from 'react';

const RaffleBanner = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  const company = useAppSelector(CompanySelectors.getCompanyData);
  const isRaffleParticipant = useMemo(() => company?.isRaffleParticipant === 'yes', [company]);

  if (pathname.includes('landing') || !isRaffleParticipant) {
    return null;
  }

  return (
    <RaffleBannerWrapper>
      <Box className='container' data-testid='raffle-banner'>
        <CoupenPhotoIcon style={{ minWidth: '24px' }} />
        <Typography variant='h5'>
          {formatMessage({
            id: 'raffle_banner.title',
            defaultMessage:
              'Register now for the competition and with a bit of luck you could win a Fitbit Charge 6!',
          })}
        </Typography>
      </Box>
    </RaffleBannerWrapper>
  );
};

export default RaffleBanner;
