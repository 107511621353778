import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { StepOne, StepOneUTwo, StepThree, StepTwo, StepZero } from './components';
import { useIntl } from 'react-intl';
import { IViolationsError, IVoucherData } from 'store/company/interfaces';
import StepSummary from './components/StepSummary';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect } from 'react';
import showToast from 'utils/showToast';
import StepperWrapper from './Stepper.styles';
import RaffleBanner from 'components/RaffleBanner';

const Stepper = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, stepNumber } = useParams<{ id: string; stepNumber: string }>();
  const data = useAppSelector(state => state.company.data);
  const hasVoucher = data?.proFit;
  const steps = hasVoucher ? [0, 1, 2, 3] : [1, 2, 3];

  useEffect(() => {
    if (data && !(steps.includes(Number(stepNumber)) || stepNumber == 'summary')) {
      navigate(`/${id}/step/${hasVoucher ? '0' : '1'}`);
    }
  }, [stepNumber, data]);

  if (!data) {
    return null;
  }
  const { name, welcomeText } = data;

  const updateStep = (stepNumber: number | string) => {
    navigate(`/${id}/step/${stepNumber}`);
  };

  const sendRequest = (error: IViolationsError[], errorVoucher?: IVoucherData) => {
    if (hasVoucher && (error.length > 0 || errorVoucher?.invalid?.length)) {
      if (error.find(item => item.propertyPath.includes('businessEmail'))) {
        navigate(`/${id}/step/1`);
        return;
      }
      if (errorVoucher?.invalid?.length) {
        navigate(`/${id}/step/0`);
        return;
      }
      showToast('error', error[0]?.message);
      navigate(`/${id}/step/0`);
      return;
    }
    if (error.length > 0) {
      if (
        error.find(item => item.code.toUpperCase() === 'NOT_UNIQUE_NAME_AND_BIRTHDATE_IN_COMPANY')
      )
        navigate(`/${id}/step/1`);
      else if (
        error.find(
          item =>
            item.propertyPath.includes('businessEmail') ||
            item.propertyPath.toLowerCase().includes('customfields'),
        )
      )
        navigate(`/${id}/step/2`);
      else navigate(`/${id}/step/3`);
    } else {
      navigate(`/${id}/successful`);
    }
  };

  const renderActiveStep = () => {
    switch (stepNumber) {
      case '0':
        return <StepZero updateStep={updateStep} />;
      case '1':
        if (id === 'profit') {
          return <StepOneUTwo updateStep={updateStep} sendRequest={sendRequest} />;
        }
        return <StepOne updateStep={updateStep} />;
      case '2':
        if (id === 'profit') {
          return <StepThree updateStep={updateStep} sendRequest={sendRequest} />;
        }
        return <StepTwo updateStep={updateStep} sendRequest={sendRequest} />;
      case '3':
        return <StepThree updateStep={updateStep} sendRequest={sendRequest} />;
      case 'summary':
        return <StepSummary updateStep={updateStep} sendRequest={sendRequest} />;
      default:
        return <StepOne updateStep={updateStep} />;
    }
  };

  return (
    <StepperWrapper>
      <RaffleBanner />
      <Box
        className={`wrapper ${id !== 'profit' ? 'fees-margin' : ''} ${
          stepNumber === 'summary' ? 'wrapper--summary' : ''
        }`}
        data-testid='stepper'>
        <Typography className='title' variant='body1'>
          {welcomeText
            ? welcomeText
            : hasVoucher
            ? intl.formatMessage({
                id: 'stepper.dynamic.title.profit',
                defaultMessage: 'Profit: hansefit sign up page',
              })
            : intl.formatMessage(
                {
                  id: 'stepper.dynamic.title',
                  defaultMessage: 'Welcome to {companyName}!',
                },
                { companyName: name },
              )}
        </Typography>
        {renderActiveStep()}
      </Box>
    </StepperWrapper>
  );
};

export default Stepper;
