import { Box, styled } from '@mui/material';

export const RaffleBannerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'linear-gradient(90deg, #CBDBF1 0%, #ACB4FF 100%)',
  marginBottom: '36px',
  padding: '16px 24px',
  '.container': {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
    marginBottom: '16px',
    '.container': {
      alignItems: 'flex-start',
    },
  },
}));
